import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const API_BASE_URL = 'https://api.bigtime-tw.com';

interface UserData {
    id: number;
    account: string;
    name: string | null;
    birthday: string | null;
    email: string | null;
    phone: string | null;
    id_card: string | null;
    real_status: 'PENDING' | 'APPROVED' | 'REJECTED';
    fileId_front_url: string | null;
    fileId_back_url: string | null;
}

const UserListPage: React.FC = () => {
    const [userData, setUserData] = useState<UserData[]>([]);
    const [loading, setLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState<UserData | null>(null);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    const fetchUserData = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${API_BASE_URL}/bo/member?page=1&limit=10`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const result = await response.json();
            if (result.success) {
                setUserData(result.data.records);
            }
        } catch {
            alert('Failed to fetch user data');
        } finally {
            setLoading(false);
        }
    };

    const handleApproveReject = async (userId: number, status: 'APPROVED' | 'REJECTED') => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${API_BASE_URL}/bo/member/real/${userId}/verify?status=${status}`, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const result = await response.json();
            if (result.success) {
                alert(`User ${status} successfully`);
                fetchUserData(); // Refresh data
            } else {
                alert('Failed to update status');
            }
        } catch {
            alert('Error processing request');
        }
    };

    const handleViewDetails = (user: UserData) => {
        setSelectedUser(user);
    };

    const handleImageClick = (imageUrl: string) => {
        setSelectedImage(imageUrl); // Set selected image for modal
    };

    const closeImageModal = () => {
        setSelectedImage(null); // Close image modal
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    return (
        <div className="d-flex">
            {/* Sidebar */}
            <div className="sidebar bg-light p-3" style={{ width: '250px' }}>
                <h5 className="mb-4">Navigation</h5>
                <ul className="nav flex-column">
                    <li className="nav-item">
                        <Link to="/user-list" className="nav-link active">
                            User List
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/photographer-list" className="nav-link">
                            Photographer List
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/label-management" className="nav-link">
                            Label Management
                        </Link>
                    </li>
                </ul>
            </div>

            {/* Main Content */}
            <div className="content p-4" style={{ flex: 1 }}>
                <h3>User List</h3>
                {loading ? (
                    <div>Loading...</div>
                ) : (
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead className="thead-light">
                            <tr>
                                <th>ID</th>
                                <th>Account</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>ID Card</th>
                                <th>Status</th>
                                <th>Details</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {userData.map((user) => (
                                <tr key={user.id}>
                                    <td>{user.id}</td>
                                    <td>{user.account}</td>
                                    <td>{user.name || 'N/A'}</td>
                                    <td>{user.email || 'N/A'}</td>
                                    <td>{user.phone || 'N/A'}</td>
                                    <td>{user.id_card || 'N/A'}</td>
                                    <td>{user.real_status}</td>
                                    <td>
                                        <button
                                            className="btn btn-info btn-sm"
                                            onClick={() => handleViewDetails(user)}
                                        >
                                            View Details
                                        </button>
                                    </td>
                                    <td>
                                        {user.real_status === 'PENDING' && (
                                            <>
                                                <button
                                                    className="btn btn-success btn-sm me-2"
                                                    onClick={() => handleApproveReject(user.id, 'APPROVED')}
                                                >
                                                    Approve
                                                </button>
                                                <button
                                                    className="btn btn-danger btn-sm"
                                                    onClick={() => handleApproveReject(user.id, 'REJECTED')}
                                                >
                                                    Reject
                                                </button>
                                            </>
                                        )}
                                        {user.real_status === 'APPROVED' && <span>Action not required</span>}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>

            {/* Details Modal */}
            {selectedUser && (
                <div
                    className="modal"
                    style={{
                        display: 'block',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                    }}
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">User Details</h5>
                                <button
                                    onClick={() => setSelectedUser(null)}
                                    className="close"
                                    style={{ border: 'none', background: 'transparent' }}
                                >
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body">
                                <div>
                                    <p><strong>ID:</strong> {selectedUser.id}</p>
                                    <p><strong>Name:</strong> {selectedUser.name || 'N/A'}</p>
                                    <p><strong>Email:</strong> {selectedUser.email || 'N/A'}</p>
                                    <p><strong>Phone:</strong> {selectedUser.phone || 'N/A'}</p>
                                    <p><strong>Status:</strong> {selectedUser.real_status}</p>
                                    <div>
                                        <strong>Front ID:</strong>
                                        {selectedUser.fileId_front_url && (
                                            <img
                                                src={selectedUser.fileId_front_url}
                                                alt="Front ID"
                                                style={{ width: '100px', height: '100px', margin: '5px', cursor: 'pointer' }}
                                                onClick={() => handleImageClick(selectedUser.fileId_front_url!)}
                                            />
                                        )}
                                    </div>
                                    <div>
                                        <strong>Back ID:</strong>
                                        {selectedUser.fileId_back_url && (
                                            <img
                                                src={selectedUser.fileId_back_url}
                                                alt="Back ID"
                                                style={{ width: '100px', height: '100px', margin: '5px', cursor: 'pointer' }}
                                                onClick={() => handleImageClick(selectedUser.fileId_back_url!)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    onClick={() => setSelectedUser(null)}
                                    className="btn btn-secondary"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Image Modal */}
            {selectedImage && (
                <div
                    className="modal"
                    style={{
                        display: 'block',
                        backgroundColor: 'rgba(0,0,0,0.8)',
                    }}
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-body text-center">
                                <img
                                    src={selectedImage}
                                    alt="Enlarged File"
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '80vh',
                                    }}
                                />
                            </div>
                            <div className="modal-footer">
                                <button onClick={closeImageModal} className="btn btn-secondary">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserListPage;
